import React from "react";

//css
import * as styles from "./ContactUs.module.css";

const TwinHeadQuarter = () => {
  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div className="row">
          <div className="col-9">
            <div className={styles.content1}>
              Currently, Twin’s service is available in India and the USA.
            </div>
          </div>
          <div className="col-12 pb-2">
            <div className={styles.title1}>USA Headquarters</div>
          </div>

          <div className="col-9 pb-2">
            <div className={styles.title2}>
              2525 East Charleston Road, Suite 104, <br />
              Mountain View, CA 94043 <br />
              United States
            </div>
          </div>
          <div className="col-12 pb-2">
            <div className={styles.title1}>India Headquarters</div>
          </div>

          <div className="col-9 pb-2">
            <div className={styles.title2}>
              Block D, 3rd Floor, <br />
              IIT Madras Research <br />
              Park Kanagam Road, <br />
              Taramani, Chennai 600 113 <br />
              India
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwinHeadQuarter;
