import React, { Component } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

// component
import ContactUsBanner from "../components/ContactUs/ContactUsBanner";
import WriteToUs from "../components/ContactUs/WriteToUs";
import TwinHeadQuarter from "../components/ContactUs/TwinHeadQuarter";

// seo
import Seo from "../components/Seo";

// services
import { sendToLSQ } from "../services/LeadSquared";
import { sendToCT } from "../services/Clevertap";

// css
import * as styles from "../components/ContactUs/ContactUs.module.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      successmsg: "Thank you for your Inquiry! We will contact you soon.",
      fields: {
        name: "",
        phone: "",
        email: "",
        category: "",
        comment: "",
        terms: false,
        reset: false,
      },
      errors: {},
    };
  }

  sendEmail = async data => {
    console.log("email sended");

    //email.
    let bodyFormData = new FormData();

    bodyFormData.set("fname", data.name);
    bodyFormData.set("phone", data.phone);
    bodyFormData.set("email", data.email);
    bodyFormData.set("category", data.category);
    bodyFormData.set("comment", data.comment);

    let url =
      "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/350/feedback";

    switch (data.category) {
      case "Complaints or Feedback":
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/345/feedback";
        break;
      case "Doctor Inquiry":
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/346/feedback";
        break;
      case "Jobs":
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/347/feedback";
        break;
      case "Business Inquiry":
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/348/feedback";
        break;
      case "Other":
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/349/feedback";
        break;
      default:
        url =
          "https://twinheastaging.wpengine.com/wp-json/contact-form-7/v1/contact-forms/350/feedback";
    }

    let successMsg =
      data.category === "Complaints or Feedback"
        ? "Thank you for bringing this to our attention! We will contact you soon."
        : "Your request has been successfully submitted, Our team will get back to you soon.";

    toast(successMsg, {
      toastId: "contactUsToast",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: false,
      toastClassName: styles.toastClassName,
    });

    try {
      const res = await axios.post(url, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("-->>", res);
    } catch (error) {
      console.log("email error", error);
    }
  };

  submit = async data => {
    this.setState({ loading: true, reset: false });

    const emailData = {
      name: data["name"],
      phone: data["phone"],
      email: data["email"],
      category: data.category || "New member Inquiry",
      comment: data["comment"],
      terms: data["terms"],
    };

    sendToCT("Contact_form_submitted", {
      action: "When user submits the form",
      name: data["name"],
      phone: data["phone"],
      email: data["email"],
      category: data.category || "New member Inquiry",
      comment: data["comment"],
    });

    // lsq
    if (data.category === undefined || data.category === "New member Inquiry") {
      // send an email
      this.sendEmail(emailData);

      // leadSquared
      let notes = "";
      if (data["comment"]) {
        notes = data["comment"];
      }
      let leadSquaredParams = {
        FirstName: data["name"],
        Phone: data["phone"],
        EmailAddress: data["email"],
        Notes: notes,
        mx_Website_CTA: "Contact-us form",
        mx_Latest_Source: "New Website",
      };

      try {
        await sendToLSQ(leadSquaredParams);
        this.setState({
          loading: false,
          successmsg: "",
          success: true,
        });
        this.resetForm();
      } catch (error) {
        console.log("lsq error", error);
        this.setState({
          loading: false,
          successmsg: "",
          success: true,
        });
        this.resetForm();
      }
    } else {
      // send an email
      this.sendEmail(emailData);
      this.setState({
        loading: false,
        successmsg: "",
        success: true,
      });
      this.resetForm();
    }
  };

  resetForm() {
    this.setState({ reset: true });
    setTimeout(() => {
      this.setState({ success: false });
    }, 5000);
  }

  render() {
    return (
      <div>
        <Seo
          title="Contact Us | Diabetes Reversal Program"
          description="Safely & sustainably reverse type 2 diabetes with Twin's AI based precision treatment.Contact us to learn about the all inclusive program delivered by top doctors"
        />

        <ContactUsBanner
          submit={this.submit}
          onChange={this.onChanges}
          error={this.state.errors}
          reset={this.state.reset}
          loading={this.state.loading}
        />
        <WriteToUs />
        <div className="d-md-none d-block">
          <TwinHeadQuarter />
        </div>
        <ToastContainer
          containerId="contactUsToast"
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          draggable={false}
          toastClassName={styles.toastClassName}
          style={{ top: "0px", right: "0px" }}
        />
      </div>
    );
  }
}

export default Contact;
