import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";

//css
import * as styles from "./ContactUs.module.css";

const ContactUsBanner = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const onSubmit = async data => {
    props.submit(data);
  };

  /*  eslint-disable-next-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (props.reset) {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reset]);

  console.log(errors);

  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div className="row">
          <div className="col-12 pt-5 pb-3">
            <div className="gradientText">Contact us </div>
          </div>
          <div className="col-12 pb-3">
            <hr />
          </div>
          <form
            name="contactUs"
            onSubmit={handleSubmit(onSubmit)}
            className={`mt-3`}
          >
            <div className="row">
              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 ${styles.formInput}`}>
                  <label htmlFor="name">Name* </label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Full Name"
                    id="name"
                    type="text"
                    {...register("name", {
                      required: "Please enter your name",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid name",
                      },
                      maxLength: { value: 50, message: "Name is too long" },
                    })}
                  />
                  {errors.name && (
                    <span className="invalid-feedback">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 ${styles.formInput}`}>
                  <label htmlFor="name">Phone* </label>

                  <input
                    className={`form-control letterSp1 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    placeholder="Contact Number"
                    id="phone"
                    type="text"
                    inputMode="numeric"
                    maxLength="10"
                    {...register("phone", {
                      required: "Please enter your mobile number",
                      pattern: {
                        value:
                          /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid phone number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Invalid phone number",
                      },
                    })}
                  />
                  {errors.phone && (
                    <span className="invalid-feedback">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 ${styles.formInput}`}>
                  <label htmlFor="name">Email* </label>

                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Email Id"
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Please enter your email",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 ${styles.formInput}`}>
                  <label htmlFor="name">Category </label>
                  <select
                    className={
                      errors.category
                        ? `form-select is-invalid ${styles.dropDown}`
                        : `form-select ${styles.dropDown}`
                    }
                    {...register("category", {
                      required: "Please select your category",
                    })}
                  >
                    <option value="New member Inquiry">
                      New member Inquiry
                    </option>
                    <option value="Complaints or Feedback">
                      Complaints or Feedback
                    </option>
                    <option value="Doctor Inquiry">Doctor Inquiry</option>
                    <option value="Jobs">Jobs</option>
                    <option value="Business Inquiry">Business Inquiry</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.category && (
                    <span className="invalid-feedback">{errors.category}</span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 ${styles.formInput}`}>
                  <label htmlFor="name">Comments </label>
                  <input
                    className={
                      errors.comment
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Comments"
                    id="name"
                    type="text"
                    {...register("comment", {})}
                  />
                  {errors.comment && (
                    <span className="invalid-feedback">
                      {errors.email.comment}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4"></div>
              <div className="col-12 col-md-4">
                <div className={`form-group mb-3 pt-3`}>
                  <div class="custom-control">
                    <input
                      className={`custom-control-input ${styles.checkbox}`}
                      type="checkbox"
                      id="flexCheckChecked"
                      {...register("terms", {
                        required: "Please check terms and conditions",
                      })}
                    />
                    <label
                      className={`custom-control-label ${styles.checkLabel}`}
                      for="flexCheckChecked"
                    >
                      I agree to all
                      <span className={styles.brownText}>
                        {" "}
                        Terms & Conditions{" "}
                      </span>
                    </label>
                    {errors.terms && (
                      <span className={styles.invalid}>
                        {errors.terms.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8"></div>

              <div className="col-12 col-md-4 pb-5">
                <div className="col-12 cursorPointer">
                  <button
                    type="submit"
                    // onClick={props.submit}
                    className={`gradientBtnWithArrow w-100 pt-2 pb-2 ${styles.submitBtn}`}
                  >
                    {!props.loading ? (
                      <>
                        <div className={styles.btntxt}>Submit </div>
                      </>
                    ) : (
                      <span className={"pt-1 pb-1"}>
                        <Loader
                          type="ThreeDots"
                          color="#FFF"
                          height={30}
                          width={30}
                          visible={props.loading}
                        />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsBanner;
