import React from "react";

// services
import { sendToCT } from "../../services/Clevertap";

//css
import * as styles from "./ContactUs.module.css";

const WriteToUs = () => {
  return (
    <div className="bgGray">
      <div className="container twinContainer">
        <div className="row pb-md-5">
          <div className="col-12 pb-3">
            <div className={styles.usText}>Write to us</div>
          </div>
          <div className="col-12 pb-3">
            <hr className="darkHr" />
          </div>

          <div className="col-12 col-md-6">
            <div className="col-12 pb-4">
              <div className={styles.salesText}>For Sales & Inquiries</div>
            </div>
            <a
              href={`tel:+919005008946`}
              className={styles.redirectLink}
              onClick={() =>
                sendToCT("contact_form_phone_number1", {
                  action: "user clicks on sales number",
                })
              }
            >
              <div className="col-12 pb-3">
                <div className="row">
                  <div className="col-2">
                    <div className={styles.callIcon}>
                      <span className="icon-call smallIcon" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div clasName="row">
                      <div className="col-12">
                        <div className={styles.number}>+91 9005008946</div>
                      </div>
                      <div className="col-12">
                        <div className={styles.number2}>
                          9 am to 9 pm Mon - Sat
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            {/* <a
              href={`mailto:twin.health@gmail.com`}
              className={styles.redirectLink}
              onClick={() =>
                sendToCT("contact_form_email1", {
                  action: "user clicks on sales email",
                })
              }
            >
              <div className="col-12 pb-4">
                <div className="row">
                  <div className="col-2">
                    <div className={styles.callIcon}>
                      <span className="icon-tablet-1 smallIcon" />
                    </div>
                  </div>
                  <div className="col-10 d-flex align-items-center">
                    <div clasName="row">
                      <div className="col-12">
                        <div className={styles.number}>
                          twin.health@gmail.com
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a> */}
            <div className="col-12 d-block d-md-none pb-3">
              <hr />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="col-12 pb-4">
              <div className={styles.salesText}>For Feedback & Complaints</div>
            </div>
            {/* <a
              href={`tel:+918939796228`}
              className={styles.redirectLink}
              onClick={() =>
                sendToCT("contact_form_phone_number2", {
                  action: "user clicks on complaint number",
                })
              }
            >
              <div className="col-12 pb-3">
                <div className="row">
                  <div className="col-2">
                    <div className={styles.callIcon}>
                      <span className="icon-call smallIcon" />
                    </div>
                  </div>
                  <div className="col-10">
                    <div clasName="row">
                      <div className="col-12">
                        <div className={styles.number}>+91 8939796228</div>
                      </div>
                      <div className="col-12">
                        <div className={styles.number2}>
                          9 a.m. to 7 p.m., Mon - Sat
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a> */}
            <a
              href={`mailto:care@twinhealth.com`}
              className={styles.redirectLink}
              onClick={() =>
                sendToCT("contact_form_email2", {
                  action: "user clicks on complaint email",
                })
              }
            >
              <div className="col-12 pb-4">
                <div className="row">
                  <div className="col-2">
                    <div className={styles.callIcon}>
                      <span className="icon-tablet-1 smallIcon" />
                    </div>
                  </div>
                  <div className="col-10 d-flex align-items-center">
                    <div clasName="row">
                      <div className="col-12">
                        <div className={styles.number}>care@twinhealth.com</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-12 pb-5">
            <hr className="darkHr" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteToUs;
