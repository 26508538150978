// extracted by mini-css-extract-plugin
export var brownText = "ContactUs-module--brownText--r6FBu";
export var btntxt = "ContactUs-module--btntxt--7Qmu7";
export var callIcon = "ContactUs-module--callIcon--SRpPC";
export var checkLabel = "ContactUs-module--checkLabel--65zBe";
export var checkbox = "ContactUs-module--checkbox--+HUCc";
export var content1 = "ContactUs-module--content1--7yx+I";
export var dropDown = "ContactUs-module--dropDown--ygoHy";
export var formInput = "ContactUs-module--formInput--hjMmd";
export var invalid = "ContactUs-module--invalid--KXQ7s";
export var number = "ContactUs-module--number--PmYFY";
export var number2 = "ContactUs-module--number2--g4DpO";
export var redirectLink = "ContactUs-module--redirectLink--5iduh";
export var salesText = "ContactUs-module--salesText--4xRrn";
export var submitBtn = "ContactUs-module--submitBtn--4Opsg";
export var title1 = "ContactUs-module--title1--4kGuV";
export var title2 = "ContactUs-module--title2--XHHnc";
export var toastClassName = "ContactUs-module--toastClassName--e+8nD";
export var usText = "ContactUs-module--usText--w8TSO";